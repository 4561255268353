import * as React from "react"
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {Screen_2} from "../screens/screen_2";
import {Screen_1} from "../screens/screen_1";
import {Screen_3} from "../screens/screen_3";
import {Screen_4} from "../screens/screen_4";

import meta from "../images/meta.jpg"

const IndexPage = () => {
  return (
    <main>
      <Header/>
      <Screen_1/>
      <Screen_2/>
      <Screen_3/>
      <Screen_4/>
      <Footer/>
    </main>
  )
}

export default IndexPage

export const Head = () => {
    return (
        <>
            <title>Influere Investigations</title>
            <meta name="description" content="DIGITAL INVESTIGATIONS TAILORED MADE FOR YOU" />
            <meta name="image" content={meta} />
            <meta property="og:image" content={meta}/>
            <meta property="twitter:image" content={meta}/>
        </>
    )
}