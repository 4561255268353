import * as React from "react"
import * as styles from './style.module.scss'

export const Screen_3 = () => {
    return (
        <div className={styles.screen}>
            <a id="about"> </a>
            <div className="container d-flex flex-column align-items-center">
                <div className="text-center">
                    <div className={"title_1 " + styles.title}>The A-Team</div>
                </div>
                <div className={styles.text + " text"}>
                    <p>Influere Investigations is compiled of a team of talents beginning with our support and compliance team filled with experience. In addition, Influere Investigations prides itself on our expertise in compliance procedures and supplying accurate findings and reports. Our uniqueness stands out with our cyber experts from the 8200 unit of the Israeli defense forces.</p>
                    <br/>
                    <p>Regardless of the reason for your investigations, we will review the case to understand if we can take on the case for moral and legal purposes. We have the right to deny any case presented to Influere Investigations.</p>
                </div>
            </div>
        </div>
    )
}