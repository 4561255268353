// extracted by mini-css-extract-plugin
export var animation = "style-module--animation--d8933";
export var button = "style-module--button--cfafe";
export var form = "style-module--form--b9ccf";
export var form_block = "style-module--form_block--b56ce";
export var input = "style-module--input--b6eb7";
export var input_left_space = "style-module--input_left_space--a7164";
export var inputs_block = "style-module--inputs_block--c3718";
export var rotateCenter = "style-module--rotate-center--14a03";
export var screen = "style-module--screen--123f5";
export var title = "style-module--title--399a7";