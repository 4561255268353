import * as React from "react"
import * as styles from './style.module.scss'

import checkIcon from "../../images/check-mark.png"
import {AnchorLink} from "gatsby-plugin-anchor-links";

const chooseUsData = [
    {
        "title": "Consult",
        "desc": "<b>Free consultation</b> to answer all your questions and reach a collective understanding for steps and expectation moving forward."
    },
    {
        "title": "Identify",
        "desc": "Identify the request and desires of our customers and present a detailed scope of the evidence in possession.",
    },
    {
        "title": "Collect",
        "desc": "Collect data from devices and accounts."
    },
    {
        "title": "Analyze",
        "desc": "Experts and our software preserve and analyze the evidence gathered."
    },
    {
        "title": "Report",
        "desc": "Receive a clear, detailed report of the findings."
    }
]
const sub_info_title = "Tailored Reporting and Consulting";
const info_text = "Influere Investigations specializes in identifying, collecting, preserving, and ultimately reporting evidence requested by our customers. Whether you are involved in a litigation case, finance reports, research findings or just want to gather evidence for a personal case, we will consult and tailor a detailed report. ";

export const Screen_2 = () => {
    return (
        <div className={styles.screen}>
            <a id="what-we-offer"> </a>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className={"title_1 " + styles.title}>What We Offer</div>
                    </div>
                    <div className="col-lg-5">
                        <div className={styles.animation_block}>
                            <div className={styles.offer_animation + " " + styles.rotateCenter}> </div>
                        </div>
                    </div>
                    <div className={"col-lg-7 col-sm-12 " + styles.offer_info_block}>
                        <div className={styles.mobile_info_block}>
                            <div className={styles.mob_animation + " " + styles.rotateCenter}> </div>
                            <div className={styles.mob_text + " text"}>{sub_info_title}</div>
                            <div className={styles.mob_text + " text"}>{info_text}</div>
                        </div>
                        <div className={"text"}><b>{sub_info_title}</b></div>
                        <br/>
                        <div className={styles.sub_text + " text"}>{info_text}</div>
                        <div className={"row " + styles.list}>
                            {
                                chooseUsData.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="inf">
                                            <h5 className={styles.sub_title}>{ item.title }</h5>
                                            <div className={"text " + styles.list_text}>
                                                <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <AnchorLink duration={0} to="#request-a-consultation" className={"button " + styles.button}>
                            <span>Request A Consultation</span>
                        </AnchorLink>
                    </div>
                </div>
            </div>
        </div>
    )
}