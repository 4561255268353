import * as React from "react"
import * as styles from './style.module.scss'

export const Screen_4 = () => {
    return (
        <div className={styles.screen}>
            <a id="request-a-consultation"> </a>
            <div className="container">
                <div className={styles.form_block}>
                    <div className="text-center">
                        <div className={"title_1 " + styles.title}>Secure a free consultation</div>
                    </div>
                    <form className={styles.form}>
                        <div className={styles.inputs_block}>
                            <input className={styles.input + " " + styles.input_left_space} placeholder="First Name*" required/>
                            <input className={styles.input} placeholder="Last Name*" required/>
                        </div>
                        <div className={styles.inputs_block}>
                            <input className={styles.input + " " + styles.input_left_space} placeholder="Email*" required/>
                            <input className={styles.input} placeholder="Phone*" required/>
                        </div>
                        <button className={"button " + styles.button}>Submit</button>
                    </form>
                </div>
                <br/><br/><br/>
                <div className="mb-5 mt-5">
                    <h4 className="text-center mb-3 title_1">Our offices:</h4>
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div style={{border: "1px solid rgb(69 66 118)", padding: '20px', borderRadius: '30px', margin: '15px'}}>
                                Rico House George Street, Prestwich<br/>
                                Manchester<br/>
                                England, M259WS
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div style={{border: "1px solid rgb(69 66 118)", padding: '20px', borderRadius: '30px', margin: '15px'}}>
                                3500 S DuPont Hwy Suite 300 Dover<br/>
                                Delaware 19901<br/>
                                United States
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.animation + " " + styles.rotateCenter}> </div>
            </div>
        </div>
    )
}