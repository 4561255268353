import * as React from "react";
import * as styles from "./style.module.scss";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Fade } from "react-awesome-reveal";
import logo from "../../images/of-partner.svg";

export const Screen_1 = () => {
  return (
    <div className={styles.screen}>
      <a id="home"> </a>
      <div className={styles.content}>
        <div className={styles.top_text}>
          <Fade duration={1500} triggerOnce>
            <div className={styles.title}>Influere</div>
            <div className={styles.sub_title}>Investigations</div>
          </Fade>
        </div>
        <div className={styles.bottom_text}>
          <Fade duration={1500} triggerOnce>
            <div className={styles.offer_text}>
              DIGITAL INVESTIGATIONS TAILOR-MADE FOR YOU
            </div>

            <AnchorLink
              className="button"
              duration={0}
              to="#request-a-consultation"
            >
              <span>Contact us</span>
            </AnchorLink>
          </Fade>
        </div>
      </div>
      <div className={styles.header_iceberg + " " + styles.wave}> </div>
    </div>
  );
};
