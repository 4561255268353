// extracted by mini-css-extract-plugin
export var bottom_text = "style-module--bottom_text--fcd30";
export var content = "style-module--content--ef4f4";
export var header_iceberg = "style-module--header_iceberg--f550a";
export var offer_text = "style-module--offer_text--97f0f";
export var partner = "style-module--partner--99180";
export var partnerLogo = "style-module--partnerLogo--e685e";
export var screen = "style-module--screen--2d27a";
export var sub_title = "style-module--sub_title--d79ef";
export var title = "style-module--title--f3859";
export var top_text = "style-module--top_text--05bdf";
export var wave = "style-module--wave--e7bb0";