// extracted by mini-css-extract-plugin
export var animation_block = "style-module--animation_block--c5f29";
export var button = "style-module--button--27588";
export var list = "style-module--list--41341";
export var listIcon = "style-module--listIcon--9e14f";
export var list_text = "style-module--list_text--d62bf";
export var mob_animation = "style-module--mob_animation--0375e";
export var mob_text = "style-module--mob_text--e2908";
export var mobile_info_block = "style-module--mobile_info_block--60175";
export var offer_animation = "style-module--offer_animation--5c39f";
export var rotateCenter = "style-module--rotate-center--97fff";
export var screen = "style-module--screen--90f40";
export var sub_text = "style-module--sub_text--5df2c";
export var sub_title = "style-module--sub_title--614d4";
export var title = "style-module--title--082fa";